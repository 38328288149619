@charset 'utf-8';

// The settings file contains the Foundation base settings, like fonts,
// colors, margins and other components.
// @see: http://foundation.zurb.com/sites/docs/sass.html#the-settings-file
@import 'settings';
@import 'font-awesome/font-awesome';

// Set the background to darker white.
body {
    background-color: $light-gray;
}

.row {
    background-color: #ffffff;
}

// The Headerphoto, with the background image.
.headerphoto {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 500px;
    min-height: 20vh;
    max-height: 36vh;
}

.column, .columns{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.headertitle {
    text-align: center;
}

[type='search'] {
    box-shadow: none;
}

ul.pagination {

    background: #DDD;
    display: inline-block;

    a {
        padding: 0.6em 1em;
        text-decoration: none;
    }

    .active a {
        color: $light-gray;
        background: $black;
    }

}


h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.5rem;
}

h1 a, h2 a, h3 a {
    text-decoration: none;
}

a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
        color: $secondary-color;
        text-decoration: none;
    }
}

p.meta {
    font-size: 90%;
    color: #666;
}

@include breakpoint(medium) {
    h1, h2{
        margin-bottom: 2rem;
    }

    img {
        margin: 0 auto 1rem;
        max-width: 80%;
        display: block;
    }
}

.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

// Style widgets using the classes for .widgetholder and .widget
.widgetholder {

    .widget {
        margin: 1.5em 0;
    }
}

// Banner on top of page.
.banner-container {
    height: 14rem;

    .banner {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #104189;
        background-size: cover;

        .row {
            height: 8rem;
            background-color: transparent;
            position: relative;
            top: 3rem;

            #logo-container {
            	height: 100%;
            	width: 100%;
            	background: rgba(255, 255, 255, 0.9);
            	padding: 0.75rem;

	            #site-logo {
	                height: 100%;
	                float: initial;
	                background-size: contain;
	                background-position: left center;
	                background-repeat: no-repeat;
	            }
            }

            @include breakpoint(medium) {
            	#logo-container {
            		background: linear-gradient(90deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7) 65%, rgba(255, 255, 255, 0) 75%);
            	}
            }

            @include breakpoint(large) {
            	#logo-container {
            		background: linear-gradient(90deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7) 45%, rgba(255, 255, 255, 0) 70%);
            	}
            }
        }
    }
}

// Top bar (sticky).
.top-bar-container {
    background-color: $secondary-color;
    color: $white;
    width: 100%;
    z-index: 100;

    .top-bar {
        background-color: inherit;
        background-color: $primary-color;
        padding: 0;

        ul {
            background-color: inherit;
            color: inherit;

            .is-dropdown-submenu {
                background-color: $primary-color;
                color: $white;
                border: none;

                li a {
                    color: $white;

                    &:hover {
                        background-color: $secondary-color;
                    }
                }
            }
        }
    }

    // Override the a inside .menu-text, for topbar navigation, where the 'homepage' has a link.
    .menu > li {
        padding: 0;

        &:hover {
            background-color: $secondary-color;

            a {
                border-bottom-color: $secondary-color;
            }
        }

        &.is-dropdown-submenu-parent {
            &:hover a, a {
                border-bottom: none !important;
            }
        }

        a {
            color: $white;
            text-decoration: none;
            padding: 1rem 1rem 0.7rem;
            border-bottom: 2px solid $primary-color;
        }

        &.active > a {
            color: $white;
            background-color: inherit;
            border-bottom: 2px solid $white;
        }
    }
}

// Special boxes for contact and login (sides).
.contact-toggle.contact-button {
    background-color: $primary-color;
    padding: 1em;
    text-align: center;
    color: $white;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 0.5em;

    &:hover {
        background-color: $secondary-color;
    }

    i.fa {
        font-size: 1.5em;
    }
}

.login-form {
    border-bottom: 2px solid $primary-color;
    border-top: 2px solid $primary-color;
    padding-top: 0.5rem;
    text-align: center;

    p, button {
        font-weight: bold;
        margin-bottom: 0.5rem;
    }

    button.btn {
        padding: 0.75em 1.5em;
        width: 100%;
    }

    input {
        margin-bottom: 0.5rem;
    }
}

// The menu in the footer.
.footer-menu {
    color: $primary-color;
    background-color: $white;

    & > li {
        padding: 0;

        a {
            color: $primary-color;
            text-decoration: none;

            &:hover {
                color: $secondary-color;
            }
        }

        &.active > a {
            color: $primary-color;
            background-color: inherit;
            border-bottom: 1px solid $primary-color;
        }
    }
}

#contact-container {
    width: 100%;
    position: relative;
    bottom: 0;
    z-index: 99;
    display: none;
    border-top: 2px solid $primary-color;

    & > .row {
    	padding-bottom: 3rem;
    }

    h2 {
        color: $primary-color;
        padding-top: 1.5rem;
    }

    label {
        font-size: 1em;
    }

    button.btn {
        font-size: 1.5em;

        &[type="submit"]:before {
            font-family: FontAwesome;
            font-size: 1em;
            content: $fa-var-envelope-o;
            display: inline-block;
            padding-right: 0.3em;
        }
    }

    div.checkbox input[type='checkbox'] + span:before,
    div.radio input[type='radio'] + span:before {
        color: $primary-color;
        background-color: $white;
    }

    #contact-close {
        position: absolute;
        top: 1em;
        right: 2em;
        color: $white;
        font-weight: bold;
        font-size: 1.25em;
        cursor: pointer;
    }

    .columns, .column {
        padding-top: 0;
        padding-bottom: 0;
    }
}

// Buttons
button.btn {
    background-color: $primary-color;
    color: $white;
    padding: 0.5em 1em;

    &:hover {
        background-color: $secondary-color;
    }

    &.btn-inv {
        background-color: $white;
        color: $primary-color;
        border-bottom-color: $white;

        &:hover {
            border-bottom-color: $primary-color;
        }
    }
}

// Boltforms overwrite
.boltform label.required:after {
    content: " *";
    color: $alert-color;
    font-weight: bold;
}

p.boltform-error {
    padding: 0.5rem 1rem;
    border-top: 2px solid $alert-color;
    color: $alert-color;
    background-color: $white;
}

div.boltforms-error {
    color: $alert-color;

    ul {
        background-color: $white;
        border-top: 2px solid $alert-color;
        padding: 0.25rem;
        margin: -0.5em 0 0.5em 0;
        list-style: none;

        &:before {
            content: "";
            display: block;
            position: relative;
            top: -0.75em;
            width: 0;
            margin-bottom: 0em;
            border-width: 0 0.5em 0.5em 0.5em;
            border-color: transparent transparent $alert-color transparent;
            border-style: none solid solid solid;
        }
    }
}

.columns.no-vert-padding {
    padding-top: 0;
    padding-bottom: 0;
}

// Better checkboxes
div.checkbox, div.radio {
    padding: 0 1.4em;
    display: inline;

    label {
        display: inline;
    }

    & > label {
        padding-left: 1.25em;
    }

    label.required:after {
        content: "";
        display: none;
    }

    input[type='checkbox'] + span:before,
    input[type='radio'] + span:before {
        content: '';
        color: $primary-color;
        width: 1em;
        height: 1em;
        display: inline-block;
        font-family: FontAwesome;
        font-size: 20px;
        top: 2px;
        position: relative;
        margin-left: -1em;
        padding-left: 0;
        padding-right: 1.5em;
        background-color: $white;
    }

    input {
        display: none;
        height: 1px;

        &[type='checkbox'] + span:before {
            content: $fa-var-square-o;
            font-family: FontAwesome;
        }

        &[type='checkbox']:checked + span:before {
            content: $fa-var-check-square-o;
            font-family: FontAwesome;
        }

        &[type='radio'] + span:before {
            content: $fa-var-circle-o;
            font-family: FontAwesome;
        }

        &[type='radio']:checked + span:before {
            content: $fa-var-dot-circle-o;
            font-family: FontAwesome;
        }
    }
}

div[data-bolt-field="teaser"] {
    color: $dark-gray;
    font-style: italic;
}

.main-container {
    font-size: 1.1rem;
    margin-top: 1.5rem;
}

.record_link {
    color: $dark-gray;
    margin-left: 1rem;

    &:hover {
        color: $black;
    }
}

.record_date {
    color: $dark-gray;
    font-style: italic;
}